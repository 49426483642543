const FEATURE_ENROLL_WITH_CODES = 'ENROLL_WITH_CODES';

const ENTERPRISE_ADMIN = 'enterprise_admin';
const ENTERPRISE_CATALOG_ADMIN = 'enterprise_catalog_admin';
const ENTERPRISE_LEARNER = 'enterprise_learner';
const ENTERPRISE_OPENEDX_OPERATOR = 'enterprise_openedx_operator';

export {
  FEATURE_ENROLL_WITH_CODES,
  ENTERPRISE_ADMIN,
  ENTERPRISE_CATALOG_ADMIN,
  ENTERPRISE_LEARNER,
  ENTERPRISE_OPENEDX_OPERATOR,
};
